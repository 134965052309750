import React from "react"
import useTranslations from "../../../utils/useTranslations"
import FeaturesList from "../../../components/featuresList"
import ComparisonPanels from "../../../components/comparisonPanels"

import heroImgWebp from "../../../images/homepage/screen-android-mobile.webp"
import heroImg from "../../../images/homepage/screen-android-mobile.png"
import heroImgWebp2x from "../../../images/homepage/screen-android-mobile@2x.webp"
import heroImg2x from "../../../images/homepage/screen-android-mobile@2x.png"

import Logo from "../../../components/logo"
import LocalizedLink from "../../../components/localizedLink"
import backgrounds from "../../../components/backgrounds"

import { ANDROID_LINK_FREE, getAndroidLink } from "../../../utils/getAndroidLink";


const heroImgAlt = "Download BitTorrent for Android phones"

class BittorrentAndroidFree extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      androidLinkFree: ANDROID_LINK_FREE,
    };
  }

  componentDidMount(){
    this.setState({
      androidLinkFree: getAndroidLink('free'),
    })
  }

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)

    const locale = this.props.pageContext.locale
    const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`

    return (
      <>
        
        <div className="top-section-container p-0 pt-md-3 background-noise-dark" style={backgrounds.darkCorners}>

          <div className="container text-dark py-2">

            <div className="row text-center mt-4 mt-sm-0">
              <div className="col-md-6 order-md-1 px-md-0 d-flex flex-column justify-content-center align-items-center">
                <Logo color="black" tag="h1" productName="Android" className="mb-4"/>
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4 android-hero"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} className="img-fluid d-block d-md-none mb-4 android-hero"></img>
                </picture>


                <p className="text-size-24 text-spacing-1 font-weight-normal">{t(`The most trusted mobile torrent downloader.`)}</p>
                <ul className="product-hero-list mt-2">
                	<li>
                		{t(`Download torrent files and magnet links.`)}
                	</li>
                	<li>
                		{t(`Save your data plan with a wifi-only mode.`)}
                	</li>
                	<li>
                		{t(`Playback files with a built-in media player.`)}
                	</li>
                </ul>
				<a href={this.state.androidLinkFree} id="product-android-free" className="index-mobile-google-play" target="_blank" rel="noopener noreferrer">
					<img className="google-play-button img-fluid" src={googlePlayButton} alt="BitTorrent for Android Google Play Store badge"></img>
				</a>
              </div>
              <div className="col-md-6 mt-5 order-md-2 d-flex justify-content-center align-items-center">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block hero-img android-hero"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} className="img-fluid d-none d-md-block hero-img android-hero"></img>
                </picture>

              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden">
          <div className="bg-text-container bg-text-gray">
            {t(`FeaturesHeader`)}
          </div>
          <div className="container mt-5 z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`FeaturesHeader`)}</p>
            <p className="text-center mb-5 text-size-24" >
              {t(`BitTorrent Android Torrent Client Features`)}
            </p>
            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden">
          <div className="bg-text-container bg-text-gray">
            {t(`Compare`)}
          </div>
          <div className="container mb-5 z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal">
                {t(`Compare Free with BitTorrent for Android Pro`)}
            </h2>
            <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":true}} >
              <p className="text-center mt-5">
                <LocalizedLink to="/products/android/bittorrent-android-pro" id="products-android-free-learnmore-pro" className="font-weight-bold">
                  {t(`Learn more`)}
                </LocalizedLink> {t(`about BitTorrent Android Pro`)}
              </p>
            </ComparisonPanels>
          </div>
        </div>

        <div className="container my-5 pt-5">
          <div className="word-cloud font-weight-bold">
          	BitTorrent <span className="font-weight-normal">
              Android
            </span><span className="font-weight-bold">
              / 
            </span> <br/> <span className="text-size-32">{t(`Product Tip`)}</span>

          </div>
        </div>

        <div className="container my-md-5 py-md-5">
        	<div className="row">
        		<div className="col-md-3 text-md-right product-faq-question mb-3 mb-md-0">
        			{t(`Why Choose BitTorrent<br><b>on Mobile?</b>`)}
        		</div>
        		<div className="offset-md-1 col-md-8 product-faq-answer">
        			{t(`<b>As the most trusted torrent downloader</b> for Android on Google Play, BitTorrent for Android is filled with features to make torrenting on Android fast and easy. Use your Android torrent client to download in bulk over wifi to save your data plan, or remotely add files to BitTorrent Classic on Windows. You can even use the app as a torrent player for your Android phone to watch media after files are downloaded to your phone.`)} 

        		</div>
        	</div>

        </div>

      </>
    )

  }
}

export default BittorrentAndroidFree
